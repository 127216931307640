<template>
    <div class="nav-left-panel">
        <ts-search :param="{}"></ts-search>

        <div class="nav-left-panel__list">
            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">企业信息</p>
                <p class="nav-left-panel__desc" v-for="item in infoList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">药品标准</p>
                <p class="nav-left-panel__desc" v-for="item in drugList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">药品研发</p>
                <p class="nav-left-panel__desc" v-for="item in devList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>

            <div class="nav-left-panel__item">
                <p class="nav-left-panel__title">竞争力分析</p>
                <p class="nav-left-panel__desc" v-for="item in newsList" :key="item.id" @click="$help.openUrl(item)">
                    {{item.viewName}}
                    <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visitId: new Date().getTime(),
                pageId: 'navCorp',
                pageName: '导航-企业所需',

                searchTxt: '',

                infoList: [],
                drugList: [],
                devList: [],
                newsList: [],
            }
        },
        created() {
            this.$help.getUrlIndex(6, 20, undefined, this.visitId)
                .then(res => {
                    this.infoList = res.data.records
                })
            this.$help.getUrlIndex(7, 20, undefined, this.visitId)
                .then(res => {
                    this.drugList = res.data.records
                })
            this.$help.getUrlIndex(8, 20, undefined, this.visitId)
                .then(res => {
                    this.devList = res.data.records
                })
            this.$help.getUrlIndex(9, 20, undefined, this.visitId)
                .then(res => {
                    this.newsList = res.data.records
                })
            this.$help.socket.send(this)

        },
        methods: {
            querySearch(queryString, cb) {
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword: queryString}).then(res => {
                    cb(res.data);
                });
            },
            search() {
                if(this.searchTxt) {
                    this.$router.push({
                        path: `/drug/${this.$help.encryption(this.searchTxt)}`,
                    })
                }
            },
        }
    }
</script>